@import "../../styles/customMediaQueries.css";

.root {
  margin: 20px 20px;
  gap: 24px;
}

.searchButton {
  font-size: 16px;
}

.searchFormContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionIcon {
  display: flex;
  cursor: pointer;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
