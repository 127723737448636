@import "../../styles/customMediaQueries.css";

.root {
  margin: 20px 20px;
  gap: 24px;

  & :global(.ant-table-wrapper .ant-table-thead th:hover) {
    background: #f0f0f0;
  }
}

.datePicker {
  width: 100%;
}

.searchFormContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionIcon {
  display: flex;
  cursor: pointer;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.formItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  @media (min-width: 576px) {
    flex-direction: row;
  }

  label {
    white-space: nowrap;
    text-align: left;
    flex-shrink: 0;

    @media (min-width: 576px) {
      min-width: 100px;
      margin-top: 5px;
    }

    @media (min-width: 992px) {
      min-width: 1px;
    }
  }

  :global(.ant-form-item) {
    width: calc(100% - 50px);
    margin-bottom: 0;
  }
}
