.root {
  margin: 20px 20px;
}

.breadcrumb {
  margin-bottom: 20px;
}

.label {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.formItem {
  margin-bottom: 10px;
}

.submitButton, 
.backButton {
  margin-top: 15px;
  width: 100%;
  font-size: 16px;
}
