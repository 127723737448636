* {
  font-family: "Roboto", sans-serif;
}

h1 {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 48px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 65px;
  }
}

h2 {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
}

body {
  background: #d8d8d8 !important;
}

.ant-layout {
  background: #d8d8d8 !important;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters,
.ant-table-wrapper .ant-table-tbody >tr >td  {
  min-width: 130px;
}

.ant-select-selector {
  text-align: left;
}

.ant-table-column-title,
.ant-table-thead .ant-table-cell {
  font-weight: bold;
}

.ant-table-cell {
  line-height: 1.2;
}

.ant-table-cell div {
  min-height: 21px;
}

.ant-table-wrapper .ant-table-cell {
  padding-right: 25px;
}
.ant-table-column-sorter.ant-table-column-sorter-full {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
}

.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell,
.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-hover >.ant-table-cell,
.ant-table-cell-row-hover {
  background-color: #daecff !important;
}

.last-frozen-column {
  border-right: 2px solid #d2d1d4;
}

.mainSectionHeader {
  font-size: 20px;
  font-weight: 600;
}

.ant-picker-panels {
  flex-direction: column !important;

  @media (min-width: 768px) {
    flex-direction: row !important;
  }
}

.ant-input-sm {
  padding: 6px 11px;
}

.ant-picker-input {
  & > input {
    font-size: 14px !important;
  }
}

.ant-select-selector,
.ant-select-lg {
  min-height: 32px !important;
  font-size: 14px !important;
}

.ant-select-selection-placeholder {
  text-align: left;
}

.ant-picker-outlined,
.ant-input-outlined {
  padding: 4.5px 11px;
  font-size: 14px;
  width: 100%;
}

.ant-select-multiple .ant-select-selector,
.ant-picker-outlined,
.ant-input-outlined {
  border-radius: 2px;
}

.format-cell {
  white-space: nowrap;
}

th.ant-table-cell {
  user-select: none;
}

th {
  border-bottom: 1px solid #d2d1d4 !important;
  font-weight: 400;
}

.mobile {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.ant-form-item-explain {
  text-align: left !important;
  margin-top: 3px;
}

.ibox-holder {
  background-color: #fff;
  padding: 14px 24px;
}

.ant-radio-button-wrapper {
  font-size: 16px;
  height: 40px;
  line-height: 38px;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 0 2px 2px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: rgba(24, 144, 255, 1);
  color: #fff;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
  color: #fff;
}

.ant-btn {
  border-radius: 2px;
}

.select-search {
  border-radius: 2px;
}

.select-search > .ant-select-selector {
  border-radius: 2px;
  min-height: 32px !important;
  text-align: left;
}

.ant-badge {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ant-badge .ant-badge-count {
  position: static;
  transform: none;
  transform-origin: center;
}