@import "../../styles/customMediaQueries.css";

.root {
  margin: 20px 20px;
  gap: 24px;
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionIcon {
  display: flex;
  cursor: pointer;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
