@import "../../styles/customMediaQueries.css";

.root {
  margin: 20px 20px;
  gap: 24px;

  & :global(.ant-table-tbody tr:nth-child(even) td) {
    background-color: #f4f4f4;
  }
}

.datePicker {
  width: 100%;
}

.searchFormContainer {
  gap: 10px;
  align-items: flex-start;

  @media (min-width: 992px) {
	  gap: 10px 0;
  }
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionIcon {
  display: flex;
  cursor: pointer;
}

.actionsWrapper {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  
  @media (min-width: 576px) {
    justify-content: flex-end;
    gap: 16px;
  }
}

.formItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  @media (min-width: 576px) {
    flex-direction: row;
  }

  label {
    white-space: nowrap;
    text-align: left;
    flex-shrink: 0;

    @media (min-width: 576px) {
      min-width: 100px;
      margin-top: 5px;
    }

    @media (min-width: 992px) {
      min-width: 1px;
    }
  }

  :global(.ant-form-item) {
    width: calc(100% - 50px);
    margin-bottom: 0;
  }
}

.sectionDatepickerFilterHolder {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & :global(.ant-picker-outlined) {
    width: auto;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

.clickableElement {
  cursor: pointer;
  color: #1677ff;
  
  &:hover, &:focus, &:visited, &:active {
    color: #0c4fae;
  }
}