.logoutText {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  margin-left: 5px;
}

.active {
  border-radius: 4px;
  background: #0030CC;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0;
}

.collapsBtn {
  position: absolute;
  top: 65px;
  right: -25px;
  background: #0030CC;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  display: flex;
  width: 50px;
  height: 30px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  z-index: 99;
  cursor: pointer;

  @media (min-width: 768px) {
    top: 30px;
    width: 24px;
    height: 24px;
    right: -12px;
  }
}

.right {
  padding-left: 25px !important;

  @media (min-width: 768px) {
    padding-left: 5px !important;
  }
}

.left {
  padding-right: 25px !important;

  @media (min-width: 768px) {
    padding-right: 5px !important;
  }
}

.hiddenText {
  opacity: 0 !important;
  transition: ease-in-out 0s !important;
}

.text {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  opacity: 1;
  min-width: 150px;
  transition: ease-in-out 0.1s;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.nav {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}

.item {
  padding: 16px 20px;
  color: #737373;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    color: #737373;
    transition: color 0s !important;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}

.activeIcon {
  path {
    stroke: #ffffff;
    fill: #ffffff;
  }
}